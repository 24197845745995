@use "../../tokens";

.input,
.comboBox {
  border-radius: tokens.$border-radius-md;
  display: flex;
  flex-direction: column;
  gap: tokens.$spacing-sm;
  position: relative;

  .inputFieldWrapper {
    position: relative;
  }

  .floatingLabel {
    border-radius: tokens.$border-radius-sm;
    backface-visibility: hidden;
    color: tokens.$color-black;
    display: inline-block;
    font-weight: 400;
    // Add a bit more space: The next spacing step is too much.
    left: calc(tokens.$spacing-sm * 1.5);
    line-height: 1em;
    overflow: hidden;
    padding: 0 calc(tokens.$spacing-xs * 0.5);
    pointer-events: none;
    position: absolute;
    text-overflow: ellipsis;
    top: 50%;
    transform-origin: top left;
    transform: translate(-0.05em, -50%) scale(1);
    transition:
      transform 0.1s ease-in-out,
      color 0.1s ease-in-out;
    user-select: none;
    white-space: nowrap;
    width: calc(100% - tokens.$spacing-sm * 1.5);
  }

  .inputField {
    background: tokens.$color-white;
    border: none;
    box-shadow: inset 0 0 0 1px tokens.$color-grey-30;
    border-radius: tokens.$border-radius-md;
    color: tokens.$color-black;
    // Add a bit more space: The next spacing step is too much.
    padding: tokens.$spacing-md calc(tokens.$spacing-sm * 1.5);
    width: 100%;

    &.noValue {
      color: tokens.$color-grey-40;
    }

    &.hasError {
      border-color: tokens.$color-error;

      &:focus {
        box-shadow: inset 0 0 0 1px tokens.$color-error;
        outline: tokens.$border-focus-width solid tokens.$color-error-focus;
      }
    }

    &:focus {
      box-shadow: inset 0 0 0 1px tokens.$color-purple-70;
      outline: tokens.$border-focus-width solid tokens.$color-purple-40;
    }

    &:disabled {
      background: none;
      box-shadow: inset 0 0 0 1px tokens.$color-grey-10;
    }
  }

  &:has(.floatingLabel) {
    ::placeholder {
      @include tokens.visually-hidden;
      color: transparent;
    }
    .inputField {
      // Move the value string off-center.
      padding: calc(tokens.$spacing-md * 1.35) calc(tokens.$spacing-sm * 1.5)
        calc(tokens.$spacing-md * 0.65);
    }
  }

  &:focus-within,
  &:not(:has(:placeholder-shown)) {
    .floatingLabel {
      color: tokens.$color-grey-40;
      // Make the floating label visually align with the input value.
      transform: translate(-0.05em, -150%) scale(0.85);
    }
  }

  .inputLabel {
    font-weight: 600;
  }

  .buttonIcon {
    position: absolute;
    right: tokens.$spacing-xs;
    top: 50%;
    transform: translateY(-50%);

    &:hover button {
      color: tokens.$color-error;
    }
  }

  .inputMessage {
    display: flex;
    align-items: center;
    color: tokens.$color-grey-40;
    gap: tokens.$spacing-xs;
    background: tokens.$color-white;
    border-radius: tokens.$border-radius-sm;
    bottom: 0;
    font: tokens.$text-body-xs;
    left: 0;
    margin: 0 tokens.$spacing-sm;
    padding: 0 tokens.$spacing-sm;
    position: absolute;
    // the negative y-offset is 50% of the line-height plus a bit extra for
    // visual balance in relation to the input border endings left and right
    transform: translateY(calc(100% - 0.85em));
  }

  &:has([aria-invalid="true"]) .inputMessage {
    color: tokens.$color-error;
  }
}
